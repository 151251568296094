import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import {Link} from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';

import {Event} from "../types/Event";
import {useTheme} from "@mui/material/styles";

const events: Event[] = [
    {
        img: "/dizengoff_mommies/lecturers/neta_tzemah.jpg",
        title: "רצפת האגן הנשית לאחר הלידה",
        lecturer: "נטע צמח - פיזיותרפיסטית רצפת אגן ואורתופדיה",
        details: "תל אביב | 23.3.25",
        link: "https://pay.grow.link/3b76c52aa8de95bfeb1eb4e86d404982-MTgwOTc2NA"
    },
    {
        img: "/dizengoff_mommies/lecturers/galia_dauban.png",
        title: "איך לפתח את הלמידה של התינוק שלי",
        lecturer: "גליה דאובה פישמן - פיזיותרפיסטית התפתחותית",
        details: "\"השמורה\" | 4.3.25",
        custom_text: "Sold Out"
    },
    {
        img: "/dizengoff_mommies/lecturers/emuna_dagan.png",
        title: "\"בוחרים נכון\" - איך נבנה סביבת אכילה מיטבית עבור הילד שלך",
        lecturer: "אמונה דגן - קלינאית תקשורת",
        details: "",
        custom_text: "בקרוב"
    },
    {
        img: "/dizengoff_mommies/lecturers/meital_manshari.png",
        title: "\"מתחברים דרך המילים\" - ספרים ודקלומים לתינוקות",
        lecturer: "מיטל מנשרי נפתלי - קלינאית תקשורת לגיל הרך",
        details: "\"השמורה\" | 17.2.25",
        custom_text: "Sold Out"
    },
    {
        img: "/dizengoff_mommies/lecturers/tal_ben_david.jpeg",
        title: "\"כשלב פוגש לב\" - יסודות יצירת הקשר עם תינוקך",
        lecturer: "טל בן דוד סלע - פסיכולוגית בהתמחות קלינית",
        details: "\"השמורה\" | 28.1.25",
        custom_text: "Sold Out"
    }
];

function UpcomingEvents() {
    const theme = useTheme();

    return (
        <Box
            pt={2}
            pb={2}
        >
            <Box>
                <Typography
                    variant="h4"
                    fontWeight={600}
                    sx={{
                        pb: 4
                    }}
                >
                    Upcoming Events
                </Typography>
            </Box>
            <Grid
                container
                rowSpacing={1}
                justifyContent={"center"}
            >
                {events.map((event, index) => (
                    <Grid
                        key={`${index}`}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            display: "flex", // Flexbox layout
                            flexDirection: "column", // Column direction for child elements
                            alignItems: "center", // Center alignment for content
                            border: "1px solid white", // Border around the grid item
                            padding: 1, // Add padding inside the grid
                            margin: 2, // Outer margin
                        }}
                    >
                        <Box
                            component="img"
                            src={event.img}
                            alt={`lecturer ${index}`}
                            sx={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain"
                            }}
                        />
                        <Typography
                            variant={"h5"}
                            sx={{
                                fontWeight: "600",
                                pt: 1,
                                pb: 1
                            }}
                        >
                            {event.title}
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "600"
                            }}
                        >
                            {event.lecturer}
                        </Typography>
                        <Typography
                            variant="h6"
                        >
                            {event.details}
                        </Typography>
                        <Box
                            sx={{
                                mt: 3,
                                mb: 2,
                            }}
                        >
                            {
                                event.link ?
                                    <Box
                                        sx={{
                                            display: "flex", // Use flexbox for alignment
                                            alignItems: "center", // Align items vertically
                                            backgroundColor: "white",
                                            color: theme.palette.background.default,
                                            borderRadius: 2,
                                            p: 1,
                                            gap: 1, // Space between the text and the icon
                                        }}
                                    >
                                        <Link
                                            href={event.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            underline={"none"}
                                            sx={{
                                                color: theme.palette.background.default,
                                            }}
                                        >
                                            להרשמה
                                        </Link>
                                        <LinkIcon/>
                                    </Box>
                                    :
                                    <Typography>
                                        {event.custom_text}
                                    </Typography>
                            }
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default UpcomingEvents;
